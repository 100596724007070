import React from "react";
import { heroData } from "../data";
import Header from "../components/Header";
const Hero = () => {
  const { title, subtitle, btnText, image } = heroData;
  return (
    <section className="lg:h-[900px] py-12" id="hero">
      <Header />
      <div className="container mx-auto  h-full relative">
        <div className="flex flex-col xl:flex-row items-center h-full md:py-24">
          {/* {text} */}
          <div className="text-center xl:text-left xl:absolute ">
            <h1
              className="h2 xl:max-w-[700px] mb-6 xl:mb-12"
              data-aos="fade-down"
              data-aos-dalay="400"
            >
              {title}
            </h1>
            <p
              className="lead xl:max-w-[380px] mb-6 lg:mb-12 text-justify "
              data-aos="fade-down"
              data-aos-dalay="500"
            >
              {subtitle}
            </p>
            <button
              className="btn  mb-8 xl:mb-0 bg-green-700 hover:bg-green-600"
              data-aos="fade-down"
              data-aos-dalay="600"
            >
              {btnText}
            </button>
          </div>
          {/* {image} */}
          <div
            className="xl:absolute xl:-right-12 xl:bottom-16 hidden sm:block"
            data-aos="fade-up"
            data-aos-dalay="700"
          >
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
