// import icons
import { FaYoutube, FaInstagram, FaGithub } from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";

// import images
import AboutImg from "../src/assets/img/AInobg.png";
// import HamzaImg from "../src/assets/img/hamza.png";
import HamzaImg from "../src/assets/img/hamza1.jpg";
// import AreebImg from "../src/assets/img/areeb.png";
import AreebImg from "../src/assets/img/areeb1.jpg";
import UzariImg from "../src/assets/img/uzair.jpg";
import OwaisImg from "../src/assets/img/owais.png";
import AlexImg from "../src/assets/img/alex.jpg";
import RehaniImg from "../src/assets/img/rehani.jpg";
import OmerImg from "../src/assets/img/omer.jpg";
// import HuzaifaImg from "../src/assets/img/huzaifa.png";
import HuzaifaImg from "../src/assets/img/huzaifa1.jpg";
import AnfImg from "../src/assets/img/ANF.png";
import HomeDaimondImg from "../src/assets/img/homedaimond.png";
import LovumImg from "../src/assets/img/lovumnobg.png";
import MyAskAIImg from "../src/assets/img/myaskai.png";
import WebDevelopment from "../src/assets/img/webdevelopment.png";
import MachineLearing from "../src/assets/img/MachineLearing.png";
import DevOps from "../src/assets/img/DevOps.png";
import Feature1Img from "../src/assets/img/features/feature1.png";
import Feature2Img from "../src/assets/img/features/feature2.png";
import MonarcaImg from "../src/assets/img/monarca.png";
import Feature3Img from "../src/assets/img/features/feature3.png";
import Feature4Img from "../src/assets/img/features/feature4.png";
import Avatar1Img from "../src/assets/img/testimonials/avatar1.png";
import Avatar2Img from "../src/assets/img/testimonials/avatar2.png";
import Avatar3Img from "../src/assets/img/testimonials/avatar3.png";
import LogoV2 from "../src/assets/img/logo-v2.png";
import HeroImage from "../src/assets/img/logo2.png";
import Feature1BgImg from "../src/assets/img/features/feature1_bg.png";
import Feature2BgImg from "../src/assets/img/features/feature2_bg.png";
import Feature3BgImg from "../src/assets/img/features/feature3_bg.png";
import Feature4BgImg from "../src/assets/img/features/feature4_bg.png";

export const navigationData = [
  {
    name: "About",
    href: "#",
    id: "about",
  },
  {
    name: "Teams",
    href: "#",
    id: "teams",
  },
  {
    name: "Features",
    href: "#",
    id: "features",
  },
  {
    name: "Testimonials",
    href: "#",
    id: "testimonials",
  },
];

export const heroData = {
  title: ` WHERE ARTIFICIAL INTELLIGENCE MEETS HUMAN INGENUITY`,
  subtitle:
    "An innovative startup that offers technical assistance to startups by harnessing the power of artificial intelligence. Our mission is to simplify the challenging task of decision-making for businesses and organizations by leveraging AI technologies to analyze data, detect patterns, and provide informed recommendations.",
  btnText: "Learn more",
  image: HeroImage,
};

export const aboutData = {
  image: AboutImg,
  title:
    "Offering Technical Assistance To Startups By Harnessing The Power Of Artificial Intelligence",
  subtitle:
    "Our mission is to simplify the challenging task of decision-making for businesses and organizations by leveraging AI technologies to analyze data, detect patterns, and provide informed recommendations.",
};

export const featuresData = {
  title: "Our Clients",
  subtitle:
    "Some of the features and advantages that we provide for those of you who store data in this Data Warehouse.",
  list: [
    {
      image: AnfImg,
      bgImage: Feature1BgImg,
      title: "Online Cloting Store",
      description: "Online Clothing Store",
      linkText: "Visit Store",
      delay: "400",
      link: "https://anfleathers.com/",
    },
    {
      image: MonarcaImg,
      bgImage: Feature2BgImg,
      title: "24 Hours Access",
      description: "Online Jewelery Store",
      linkText: "Visit Store",
      delay: "500",
      link: "https://www.monarcajewels.com/",
      bg: "bg-black rounded-lg",
    },
    {
      image:
        "https://images.squarespace-cdn.com/content/v1/62e395d827b2036129dcaab8/94e5441a-ae71-4207-8212-6774703f6991/Vendor+marketplace+%284%29.png?format=1500w",
      bgImage: Feature3BgImg,
      title: "Print Out",
      description: "Prop-Tech Company",
      linkText: "Visit",
      delay: "400",
      link: "https://www.rehanisoko.com/",
    },
    {
      image: LovumImg,
      bgImage: Feature4BgImg,
      title: "Security Code",
      description: "Consultation Organization",
      linkText: "Visit",
      delay: "500",
      link: "https://lovumgroup.com/",
      bg: "bg-transparent",
    },
    {
      image: MyAskAIImg,
      bgImage: Feature1BgImg,
      title: "Online Cloting Store",
      description: "Customizable AI Knowledge Solutions Provider.",
      linkText: "Visit",
      delay: "400",
      link: "https://myaskai.com/",
    },
    {
      image: "https://prices.vn/frontend/v2/images/logo.svg",
      bgImage: Feature2BgImg,
      title: "24 Hours Access",
      description: "E-Commerce Store",
      linkText: "Visit Store",
      delay: "500",
      link: "https://prices.vn/",
    },
    {
      image: HomeDaimondImg,
      bgImage: Feature1BgImg,
      title: "Home Daimond",
      description: "Home Daimond",
      linkText: "Visit Store",
      delay: "400",
      link: "https://diamondhomeprotection.com/",
    },
  ],
};
export const TeamsData = {
  title: "The Team Behind AIgnitiveX",
  subtitle:
    "Some of the features and advantages that we provide for those of you who store data in this Data Warehouse.",
  list: [
    {
      image: OwaisImg,
      bgImage: Feature2BgImg,
      title: "Owais Ahmed",
      subtitle: "Founder",
      description: "Machine Learning Engineer | Computer Vision Engineer",
      linkText: "Learn more",
      github: "https://www.linkedin.com/in/owais-ahmed-749911192/",
      delay: "500",
      bg: "rounded-lg",
    },
    {
      // image: Feature1Img,
      image: HamzaImg,
      bgImage: Feature1BgImg,
      title: "Hamza Ali",
      subtitle: "Co Founder",
      description: "Machine Learning Engineer | Full Stack Developer",
      linkText: "Learn more",
      github: "https://www.linkedin.com/in/hamza-ali-khan-5781b0200/",
      delay: "400",
      bg: "rounded-lg",
    },

    {
      image: HuzaifaImg,
      bgImage: Feature3BgImg,
      title: "Muhammad Huzaifa",
      subtitle: "Co Founder",
      description: "MERN Stack Developer | Python Developer",
      linkText: "Learn more",
      github: "https://www.linkedin.com/in/muhammad-huzaifa-2a3821249/",
      delay: "1000",
      bg: "rounded-lg",
    },
    {
      image: AreebImg,
      bgImage: Feature4BgImg,
      title: "Muhammad Areeb",
      subtitle: "Co Founder",
      description: "Full Stack Developer",
      linkText: "Learn more",
      github: "https://www.linkedin.com/in/muhammad-areeb-1844a5192/",
      delay: "1300",
      bg: "rounded-lg",
    },
    // {
    //   image: UzariImg,
    //   bgImage: Feature1BgImg,
    //   title: "Uziar Abid",
    //   subtitle: "Co Founder",
    //   description: "DevOps Developer | Machine Learning Engineer",
    //   linkText: "Learn more",
    //   github: "https://www.linkedin.com/in/muhammad-areeb-1844a5192/",
    //   delay: "1300",
    //   bg: "rounded-lg",
    // },
  ],
};

export const testimonialsData = [
  {
    // image: Avatar1Img,
    image: AlexImg,
    name: "Alex Rainey",
    web: "myaskai.com",
    message:
      "Big thank you to AIgnitiveX for all their support in building out our AI backend. Great team for anyone looking to start making use of OpenAI or Pinecone.",
    delay: "300",
  },
  {
    // image: Avatar2Img,
    image: RehaniImg,
    name: "Rehani Soko",
    web: "rehanisoko.com",
    message:
      "I am delighted to share that Rehani Soko has collaborated with AIgnitiveX, a remarkable startup, to provide us with comprehensive technical assistance. Together, we have successfully implemented a data pipeline, helping revolutionize our operations.",
    delay: "600",
  },
  {
    // image: Avatar3Img,
    image: OmerImg,
    name: "Omar Cholico",
    web: "monarcajewels.com",
    message:
      "Thank you so much guys for your hard work, this is just the beginning!",
    delay: "900",
  },
];

export const ctaData = {
  title: "Try for free!",
  subtitle: "Get limited 1 week free try our features!",
  btnText1: "Learn more",
  btnText2: "Request Demo",
};

export const footerData = {
  logo: LogoV2,
  address: "Karachi, Pakistan",
  email: "aignitivex@gmail.com",
  phone: "+92 (312) 202 9205 (Main)",
  list1: [
    {
      name: "Profile",
      href: "#",
    },
    {
      name: "Features",
      href: "#",
    },
    {
      name: "Careers",
      href: "#",
    },
    {
      name: "DW News",
      href: "#",
    },
  ],
  list2: [
    {
      name: "Support",
      href: "#",
    },

    {
      name: "Guide",
      href: "#",
    },
    {
      name: "Reports",
      href: "#",
    },
    {
      name: "Q & A",
      href: "#",
    },
  ],
  socialList: [
    {
      icon: <FaYoutube />,
      href: "#",
    },
    {
      icon: <FaInstagram />,
      href: "#",
    },
    {
      icon: <FaGithub />,
      href: "https://github.com/AIgnitiveX",
    },
  ],
};

export const copyrightData = {
  text: "© AIgnitiveX™, 2020. All rights reserved. Company Registration Number: 21479524.",
  icon: <BsChatDotsFill />,
};

export const Technologies = {
  title: "Our Expertise",
  list: [
    {
      image: WebDevelopment,
      bgImage: Feature4BgImg,
      title: "Web Development",
      subtitle: "Frontend",
      description: "• React • Next • Vue • Angular • React Native • Flutter ",
      subtitle2: "Backend",
      description2: "• NestJs •  Express • Django • Flask • DotNet",
      subtitle3: "CRM Development",
      description3: "• Wordpress • Shopify • Wix",
      delay: "500",
      bg: "rounded-lg",
    },
    {
      image: MachineLearing,
      bgImage: Feature2BgImg,
      title: "Artificial Intelligence",
      subtitle: "Machine Learning",
      description: "• Demand Forecasting",
      description2: "• Optimization Algorithms",
      description3: "• Computer Vision",
      description4: "• LLMs",
      delay: "500",
      bg: "rounded-lg",
    },
    {
      image: DevOps,
      bgImage: Feature2BgImg,
      title: "Cloud And DevOps",
      description: "• AWS • GCP • Azure",
      description2: "• CI/CD",
      description3: "• Terraform",
      delay: "500",
      bg: "rounded-lg",
    },
  ],
};
