import React from "react";

import { ctaData } from "../data";

import { BsArrowRight } from "react-icons/bs";

const CtaSection = () => {
  // destructure cta data
  const { title, subtitle, btnText1, btnText2 } = ctaData;
  return (
    <section
      className="my-[75px] xl:my-[150px] border-t-2"
      data-aos="fade-up"
      id="ctasection"
    >
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row justify-between items-center">
          {/* text */}
          {/* <div className="py-12 xl:py-24 text-center xl:text-left">
            <h2 className="h2 mb-5 " data-aos="fade-up" data-aos-delay="300">
              {title}
            </h2>
            <p className="lead" data-aos="fade-up">
              {subtitle}
            </p>
          </div> */}
          {/* buttons */}
          {/* <div className="flex flex-col xl:flex-row gap-y-4 gap-x-[30px]">
            <button
              className="btn btn-secondary bg-green-700 hover:bg-green-600"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {btnText1}
            </button>
            <button
              className="btn btn-quaternary hover:bg-green-700 flex items-center gap-x-[20px] group"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              {btnText2}
              <BsArrowRight className="text-2xl text-green-700 transition group-hover:text-white" />
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
