import React, { useState } from "react";

import Logo from "../assets/img/logo(bg).png";
import Logov2 from "../assets/img/logo2.png";
import Nav from "../components/Nav";
import NavMobile from "../components/NavMobile";

import { FaBars } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";

const Header = () => {
  const [navMobile, setNavMobile] = useState(false);
  return (
    <header
      className="mb-12 lg:mb-0 z-20  h-[100px] px-4 lg:px-0 w-full relative"
      data-aos="fade-down"
      data-aos-delay="500"
    >
      <div className="container mx-auto">
        <div className="flex items-center justify-between ">
          <div className="flex items-center gap-x-[120px] ">
            <a href="#">
              <img
                src={Logo}
                alt=""
                className="w-[px] h-[35px] hidden md:block bg-transparent"
              />
              <img
                src={Logov2}
                alt=""
                className="w-[px] h-[35px] block md:hidden bg-transparent"
              />
            </a>
            {/* nav - initially is hidden / show on large screens */}
            <div className="hidden lg:flex">
              <Nav />
            </div>
          </div>
          {/* mobile nav -initially is showing / hiddn on large screen
           */}
          <div
            className={`${
              navMobile ? "max-h-52" : "max-h-0"
            } lg:hidden absolute top-24 w-full left-0 right-0 bg-green-700 font-bold rounded transition-all overflow-hidden`}
          >
            <NavMobile />
          </div>

          {/* button */}
          <button className="btn btn-quaternary flex hover:bg-green-700 items-center gap-x-[20px] group ">
            Contact Us{" "}
            <BsArrowRight className="text-2xl text-green-700 group-hover:text-white transition" />
          </button>

          {/* nav trigger btn/ only shows on mobile screens */}
          <div
            onClick={() => setNavMobile(!navMobile)}
            className="lg:hidden text-2xl text-primary cursor-pointer"
          >
            <FaBars />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
