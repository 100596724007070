import React from "react";

import { navigationData } from "../data";

const Nav = () => {
  const scrolltoContainer = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      // window.scrollBy({ top: -80, behavior: "smooth" }); // for Chrome, Firefox, Safari
      // window.scrollTo({ top: -80, behavior: "smooth" }); // for IE and Edge
    }
  };
  return (
    <nav>
      <ul className="flex gap-x-8  cursor-pointer">
        {navigationData.map((item, index) => {
          return (
            <li
              className=""
              key={index}
              onClick={() => scrolltoContainer(item.id)}
            >
              <div>{item.name}</div>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
